<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Timeclock Manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="user.roles.includes('Technology Services')" to="/scotswork/timeclock/usage" icon>
          <v-icon>fal fa-calendar-alt</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" to="/scotswork/timeclock/detail" exact icon>
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View Timeclock scan detail</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table :items="timeclocks" :headers="headers" :items-per-page="timeclocks.length"  :footer-props="{ 'items-per-page-options': [20, 30] }" @dblclick:row="(e, { item }) => { loadDetail(item._id) }">
        <template v-slot:item.ip="{ item }">
          <a :href="'http://' + item.ip + ':2323'" target="_blank">{{ item.ip }}</a>
        </template>
        <template v-slot:item.lastPing="{ item }">
          <v-alert v-if="item.pingSeconds > 300" type="error" dense style="margin-bottom:0">{{ item.pingTimeAgo }}</v-alert>
          <v-alert v-else-if="item.pingSeconds > 120" type="warning" dense style="margin-bottom:0">{{ stringFormatDateToday(item.lastPing) }}</v-alert>
          <v-alert v-else-if="item.lastPing" type="success" dense style="margin-bottom:0">{{ stringFormatDateToday(item.lastPing) }}</v-alert>
          <v-alert v-else type="error" dense style="margin-bottom:0">Configured; never pinged server</v-alert>
        </template>
        <template v-slot:item.lastScan="{ item }">{{ stringFormatDateToday(item.lastScan) }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click="loadDetail(item._id)">
                <v-icon>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>View timeclock scan detail for {{ item.location }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate, stringFormatDateToday } from '../../../helpers/formatters'
import { getTimeDiff } from '../../../helpers/diffs'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const timeclocks = ref([])
    const headers = ref([
      { text: 'Location', value: 'shortName' },
      { text: 'IP Address', value: 'ip' },
      { text: 'Last Ping', value: 'lastPing' },
      { text: 'Last Scan', value: 'lastScan' },
      { text: '', value: 'actions' }
    ])
    const filter = computed({
      get: () => root.$store.state['work-study'].timeclockFilter,
      set: (val) => {
        root.$store.commit('work-study/setTimeclockFilter', val)
        load()
      }
    })

    const service = root.$feathers.service('timeclock/location')

    async function load () {
      const query = { $limit: 50, $sort: { location: 1 } }
      const dt = new Date()
      dt.setMinutes(dt.getMinutes() - 5)
      switch (filter.value) {
        case 'Online':
          query.ping = { $gte: dt }
          break
        case 'Offline':
          query.ping = { $lte: dt }
          break
        case 'Unconfigured':
          query.locked = false
          break
      }
      const { data } = await service.find({ query })
      const ids = data.map(({ _id }) => _id)
      const { data: inData } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate: [{ $match: { 'in.timeclock': { $in: ids } } }, { $group: { _id: '$in.timeclock', lastScan: { $max: '$in.date' } } }] } })
      const { data: outData } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate: [{ $match: { 'out.timeclock': { $in: ids } } }, { $group: { _id: '$out.timeclock', lastScan: { $max: '$out.date' } } }] } })

      timeclocks.value = data.map((row) => {
        const inRec = inData.find(({ _id }) => _id === row._id)
        const outRec = outData.find(({ _id }) => _id === row._id)
        if (inRec && outRec) {
          row.lastScan = inRec.lastScan > outRec.lastScan ? inRec.lastScan : outRec.lastScan
        } else if (inRec) {
          row.lastScan = inRec.lastscan
        } else if (outRec) {
          row.lastScan = outRec.lastScan
        }
        return updateTimeLabel(row)
      })
      if (interval == null) {
        interval = setInterval(load, 10000)
      }
    }

    function updateTimeLabel (temp) {
      temp.pingSeconds = getTimeDiff(new Date(), temp.lastPing, true)
      const diff = getTimeDiff(new Date(), temp.lastPing).split(',')
      temp.pingTimeAgo = 'More than ' + diff[0] + (diff.length > 1 ? ' ago' : '')
      return temp
    }
    // const onPatchedListener = (data) => {
    //   for (let i = 0; i < timeclocks.value.length; i++) {
    //     if (timeclocks.value[i]._id === data._id) {
    //       data.pingSeconds = 0
    //       data.pingTimeAgo = '0 seconds ago'
    //       timeclocks.value.splice(i, 1, { ...data, pingSeconds: 0, pingTimeAgo: '0 seconds ago' })
    //     }
    //   }
    // }

    let interval = null
    onMounted(() => {
      load()
      // service.on('patched', onPatchedListener)
    })
    onBeforeUnmount(() => {
      // service.removeListener('patched', onPatchedListener)
      clearInterval(interval)
    })

    const newTimeclockDialog = ref(false)
    const newTimeclockLocation = ref('')
    function saveNewTimeclock () {
      service.create({ location: newTimeclockLocation.value, locked: false }).then(() => {
        newTimeclockDialog.value = false
        load()
      })
    }

    function loadDetail (id) {
      root.$store.commit('work-study/setTimeclockFilter', { timeclocks: [id], student: '', dates: [] })
      root.$router.push('/scotswork/timeclock/detail')
    }

    return {
      user,
      roles,
      timeclocks,
      headers,
      filter,
      newTimeclockDialog,
      newTimeclockLocation,
      saveNewTimeclock,
      loadDetail,
      stringFormatDate,
      stringFormatDateToday
    }
  }
}
</script>
