var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Timeclock Manager")]),_c('v-spacer'),(_vm.user.roles.includes('Technology Services'))?_c('v-btn',{attrs:{"to":"/scotswork/timeclock/usage","icon":""}},[_c('v-icon',[_vm._v("fal fa-calendar-alt")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":"/scotswork/timeclock/detail","exact":"","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}])},[_c('span',[_vm._v("View Timeclock scan detail")])])],1),_c('v-data-table',{attrs:{"items":_vm.timeclocks,"headers":_vm.headers,"items-per-page":_vm.timeclocks.length,"footer-props":{ 'items-per-page-options': [20, 30] }},on:{"dblclick:row":function (e, ref) {
var item = ref.item;
 _vm.loadDetail(item._id) }},scopedSlots:_vm._u([{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'http://' + item.ip + ':2323',"target":"_blank"}},[_vm._v(_vm._s(item.ip))])]}},{key:"item.lastPing",fn:function(ref){
var item = ref.item;
return [(item.pingSeconds > 300)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"error","dense":""}},[_vm._v(_vm._s(item.pingTimeAgo))]):(item.pingSeconds > 120)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"warning","dense":""}},[_vm._v(_vm._s(_vm.stringFormatDateToday(item.lastPing)))]):(item.lastPing)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"success","dense":""}},[_vm._v(_vm._s(_vm.stringFormatDateToday(item.lastPing)))]):_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"error","dense":""}},[_vm._v("Configured; never pinged server")])]}},{key:"item.lastScan",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDateToday(item.lastScan)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadDetail(item._id)}}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View timeclock scan detail for "+_vm._s(item.location))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }